import type { GetUsersQuery, GetUsersResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getUsers(query: GetUsersQuery): Promise<GetUsersResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/users`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined) {
        url.searchParams.append(key, value.toString())
      }
    })
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const usersQuery = (queryParams: GetUsersQuery) => ({
  queryKey: ['users', queryParams],
  queryFn: () => getUsers(queryParams),
})
