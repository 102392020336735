import { useToast } from '@chakra-ui/react'
import { upperFirst } from 'lodash'
import { useState } from 'react'

import { getTokens } from './auth'

export enum DownloadType {
  Refresh = 'Refresh',
  DownloadNew = 'Download',
}
export enum RequestType {
  CompetitorsRequest = 'CompetitorsRequest',
  TournamentsRequest = 'TournamentsRequest',
}
export type StatusType = 'success' | 'error'

export const useCompetitionSync = () => {
  const [sending, setSending] = useState(false)
  const toast = useToast()

  const sendFeedToast = (type: RequestType, status: StatusType) => {
    toast({
      title: `${upperFirst(type)} feed ${status === 'success' ? 'sent successfully' : 'send failed'}`,
      status: status,
      duration: 3000,
    })
  }

  const sendRefreshDLRequest = async (type: RequestType, compId: string, clearCache: boolean = false) => {
    setSending(true)
    const tokens = getTokens()
    let baseUrl = `${import.meta.env.VITE_API_URL}sds/comp/refresh/${compId}`
    if (type === RequestType.TournamentsRequest) {
      baseUrl = `${import.meta.env.VITE_API_URL}sds/comp/download/${compId}`
    }

    const url = new URL(baseUrl)
    if (clearCache) {
      url.searchParams.append('clearCache', 'true')
    }

    try {
      const response = await fetch(url.toString(), {
        method: 'PUT',
        headers: { Authorization: `Bearer ${tokens?.accessToken}` },
      })
      if (response) {
        setSending(false)
        sendFeedToast(type, response.ok ? 'success' : 'error')
      }
    } catch (error) {
      console.warn('Error sending Feed', error) // eslint-disable-line no-console
      setSending(false)
      sendFeedToast(type, 'error')
    }
  }

  return {
    sending,
    sendRefreshDLRequest,
  }
}
