import { Button, Code, Flex, FormControl, FormLabel, Heading, Icon, Link, Select, Stack } from '@chakra-ui/react'
import type { GetUsersQuery, GetUsersResponse, UserDetailed } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { InfiniteData, QueryClient } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { usersQuery } from '../../../api/get-users'
import { ListPageFilters } from '../../../components/list-page-filters'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type UsersFilters = GetUsersQuery

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    const usersQueryOpts = usersQuery(urlParams)

    return (
      queryClient.getQueryData<InfiniteData<GetUsersResponse>>(usersQueryOpts.queryKey) ??
      (await queryClient.fetchInfiniteQuery(usersQueryOpts))
    )
  }

export default function Users() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const initialUsersData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: users, ...usersQueryResult } = useInfiniteQuery({
    ...usersQuery(params),
    initialData: initialUsersData,
  })

  const filtersFormDefaultValues = {
    status: params?.status,
    email: params.email ?? '',
  } as UsersFilters

  const { register, handleSubmit, reset } = useForm<UsersFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleSubmitClick = (formData: UsersFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string>>(
      (acc, [key, val]) => ({
        ...(val && { [key]: val }),
        ...acc,
      }),
      {}
    )

    setSearchParams(newSearchParams)
  }

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const rows = users?.pages.flatMap(page => [...page.data])

  const columns: ColumnDef<UserDetailed>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        cell: ({ row }) => (
          <Link as={RouterLink} to={row.original.user?.id}>
            {row.original.user?.id}
          </Link>
        ),
      },
      {
        accessorKey: 'givenName',
        header: 'Given Name',
        accessorFn: row => row.user?.givenName,
      },
      {
        accessorKey: 'familyName',
        header: 'Family Name',
        accessorFn: row => row.user?.familyName,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        accessorFn: row => row.user?.email,
      },
      {
        accessorKey: 'Enabled',
        accessorFn: row => (row.user?.enabled ? 'true' : 'false'),
        header: 'Enabled',
        cell: ({ row }) => (
          <FontAwesomeIcon
            icon={['fas', row.original.user?.enabled ? 'check-circle' : 'circle-xmark']}
            size="sm"
            style={{ marginRight: '8px', fontSize: '12px' }}
          />
        ),
      },
    ],
    []
  )

  return (
    <>
      <Heading as="h2" size="lg">
        Users
      </Heading>

      <Stack w="100%" spacing={8} my={6}>
        <Flex justifyContent="space-between">
          {
            <ListPageFilters
              formId="users-filters"
              onDismiss={handleDismissFilters}
              onReset={handleResetClick}
              onSubmit={handleSubmit(handleSubmitClick)}
              title="Filter users"
            >
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input {...register('email')} />
              </FormControl>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select {...register('status')}>
                  <option value="">Select a status</option>
                  <option value="UNCONFIRMED">Unconfirmed</option>
                  <option value="CONFIRMED">Confirmed</option>
                  <option value="ARCHIVED">Archived</option>
                  <option value="COMPROMISED">Compromised</option>
                  <option value="UNKNOWN">Unknown</option>
                  <option value="RESET_REQUIRED">Reset Required</option>
                  <option value="FORCE_CHANGE_PASSWORD">Force Change Password</option>
                </Select>
              </FormControl>
            </ListPageFilters>
          }

          <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
            <Button as={RouterLink} to="create" variant="primary" leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}>
              New user
            </Button>
          </HasMCAccess>
        </Flex>

        {usersQueryResult.isError ? (
          <NonIdealState
            title="Error fetching users"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {usersQueryResult.error instanceof Error
                ? usersQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : rows?.length ? (
          <Table columns={columns} data={rows} />
        ) : (
          <NonIdealState
            title="No users found"
            description={
              paramsCount > 0 ? "We couldn't find any users matching your filters" : "We couldn't find any users"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
        {usersQueryResult.isSuccess && usersQueryResult.hasNextPage && (
          <Button
            mt={8}
            variant="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'ellipsis']} />}
            isLoading={usersQueryResult.isFetchingNextPage}
            onClick={() => usersQueryResult.fetchNextPage()}
          >
            Load more
          </Button>
        )}
      </Stack>
    </>
  )
}
